/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});

// Headroom
import Headroom from "headroom.js";
var myElement = document.querySelector("header");
var headroom = new Headroom(myElement);
headroom.init();

//Image carousel
var carouselSwiper = document.querySelectorAll('.content-block.is-image-carousel-block');
if (carouselSwiper.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		carouselSwiper.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				swiperButtons = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtons.setAttribute('class', 'swiper-buttons');
			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			swiperButtons.appendChild(swiperButtonNext);
			swiperButtons.appendChild(swiperButtonPrev);
			swiperContainer.parentElement.appendChild(swiperButtons);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

// Slideshow
var slideshowSwiper = document.querySelectorAll('.slideshow .swiper-container');
if (slideshowSwiper.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(".slideshow .swiper-container", {
			grabCursor: true,
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			watchOverflow: true,
			loop: true,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination-vertical',
				clickable: true
			}
		});
	})();
	var slides = document.querySelectorAll('.slideshow .swiper-container .slideshow-slide');
	if (slides.length == 1) {
		var paging = document.getElementById('slideshow-paging');
		paging.classList.add('disabled');
	}
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('side-pop-up'), navigation = document.querySelector('.side-pop-up'), navigationContainer = document.querySelector('.side-pop-up__container');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
		} else {
			focusLock.off(navigationContainer);
		}
	};

	navigation.addEventListener('click', e => {
		if (e.target.className == "side-pop-up__container") {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

//language language - sidebar

//Close language dropdown on click outside bounds
const langToggler = document.getElementById('language');
if (langToggler) {

	const toggle = checked => {
		langToggler.checked = checked;
	};
	
	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && langToggler.checked) {
			toggle(false);
		}
	});
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Jump to submitted form
document.querySelectorAll('.contact-block').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});